<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-header>
          Detail User <strong>( {{ this.name }} )</strong>
      </b-card-header>
      <div class="loading" v-if="isLoading == true">
        <div class="sk-three-bounce">
          <div class="sk-child sk-bounce1"></div>
          <div class="sk-child sk-bounce2"></div>
          <div class="sk-child sk-bounce3"></div>
        </div>
      </div>
      <b-card-body>
          <b-row class="mb-4">
              <b-col sm="4">
                  <h6>Name</h6>
                  <div class="mb-3" v-if="this.name == null"><strong>-</strong></div>
                  <div class="mb-3" v-else><strong>{{ this.name }}</strong></div>
                  <h6>Email</h6>
                  <div class="mb-3" v-if="this.email == null"><strong>-</strong></div>
                  <div class="mb-3" v-else><strong>{{ this.email }}</strong></div>
                  <h6>Balance</h6>
                  <div class="mb-3" v-if="this.balance == null"><strong>-</strong></div>
                  <div class="mb-3" v-else><strong>{{ this.balance}}</strong></div>
              </b-col>
              <b-col sm="4">
                  <h6>Transaction Type</h6>
                  <div class="mb-3" v-if="this.transaction_type == null"><strong>-</strong></div>
                  <div class="mb-3" v-else><strong>{{ this.transaction_type }}</strong></div>
                  <h6>Transaction Status</h6>
                  <div class="mb-3" v-if="this.transaction_status == null"><strong>-</strong></div>
                  <div class="mb-3" v-else><strong>{{ this.transaction_status }}</strong></div>
                  <h6>Amount</h6>
                  <div class="mb-3" v-if="this.amount == null"><strong>-</strong></div>
                  <div class="mb-3" v-else><strong>{{ this.amount | currency }}</strong></div>
              </b-col>
              <b-col sm="4">
                  <h6>Withdraw Destination</h6>
                  <div class="mb-3" v-if="this.withdraw_destination == null"><strong>-</strong></div>
                  <div class="mb-3" v-else><strong>{{ this.withdraw_destination }}</strong></div>
                  <h6>Account Number</h6>
                  <div class="mb-3" v-if="this.account_number == null"><strong>-</strong></div>
                  <div class="mb-3" v-else><strong>{{ this.account_number }}</strong></div>
                  <h6>Transaction Date</h6>
                  <div class="mb-3" v-if="this.created_at == null"><strong>-</strong></div>
                  <div class="mb-3" v-else><strong>{{ this.created_at }}</strong></div>
              </b-col>
          </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

  export default {
    name: 'detail-wallet-monitor',
    data() {
      return {
        created_at: '',
        transaction_type: '',
        withdraw_destination: '',
        account_number: '',
        amount: '',
        balance: '',
        name: '',
        email: '',
        transaction_status: ''
      }
    },
    created () {
      this.$http.get(`monitor-wallet/` + this.$route.params.id)
      .then((res) => {
        let result = res.data.data
        this.created_at = result.created_at
        this.transaction_type = result.transaction_type
        this.transaction_status = result.transaction_status
        this.amount = result.amount
        this.balance = result.users.wallet_balance
        this.name = result.users.name
        this.email = result.users.email
        this.withdraw_destination = result.destination_name
        this.account_number = result.destination_va
      })
    },
}
</script>

<style>

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
